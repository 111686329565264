<template>
    <el-dialog :title="title" :visible.sync="addProductVisible" width="60%" @close="close" v-if="addProductVisible">
        <el-form ref="form" v-bind:model="form" v-loading.body="loading" label-position="right" label-suffix=":" label-width="100px" size="large" element-loading-text="上传中">
            <div class="search" style="overflow:hidden;margin-bottom:20px">
                <el-input class="s-input" v-model="queryProductParams.title" placeholder="商品名称" clearable></el-input>
                <el-input class="s-input" v-model="queryProductParams.source_id" placeholder="中台商品id" clearable></el-input>
                <el-input class="s-input" v-model="queryProductParams.goodssn" placeholder="商品编码" clearable></el-input>
                <el-select v-model="queryProductParams.brand_id" placeholder="请选择品牌" class="handle-select form-input">
                    <el-option v-for="items in brandList" :key="items.id" :label="items.name" :value="items.id"></el-option>
                </el-select>
                <div style="float: right">
                    <el-button type="primary" @click="getSearchGoodsInfo()">搜索</el-button>
                </div>
            </div>
            <el-table v-loading="loading" element-loading-text="数据加载中" stripe border :data="searchGoodsList" @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="55" align="center"></el-table-column>
                <el-table-column label="商品" width="250" align="center">
                    <template slot-scope="scope">
                        <div style="display: flex">
                            <img class="product_img" :src="scope.row.thumb" width="50" height="60" style="flex:0 0 50px;margin-right:10px" />
                            <div>{{scope.row.title}}</div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="品牌" prop="brand.name" align="center"></el-table-column>
                <el-table-column label="进货价" prop="costprice" align="center"></el-table-column>
                <el-table-column label="销售价" prop="productprice" align="center"></el-table-column>
                <el-table-column label="吊牌价" prop="marketprice" align="center"></el-table-column>
                <el-table-column label="库存" prop="stock" align="center"></el-table-column>
            </el-table>
            <Pagination v-bind:pageInfo="pageInfo" v-on:refreshPageSize="refreshPageSize" v-on:refreshPageNum="refreshPageNum" />
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="close">取 消</el-button>
            <el-button type="primary" @click="submitProduct">提 交</el-button>
        </div>
    </el-dialog>
</template>
<script>
import Pagination from '@/components/Pagination';
import {
    request
} from '@/common/request';
import {
    deal
} from '@/common/main';
export default {
    name: 'Addproduct',
    components: {
        Pagination,
    },
    props: ['addVisible', 'form', 'goodsList',], // 声明自定义属性
    data() {
        return {
            activeName: '商品分类',
            stretch: true,
            queryProductParams: {
                page: 1,
                pagesize: 10,
                state: 2
            },
            selectProductList: [],
            addProductVisible: false,
            title: "选择商品",
            loading: false,
            searchGoodsList: [],
            pGoodsList: [],
            brandList: [], //品牌列表
            pageInfo: {},
        };
    },
    created() {
        this.getSearchGoodsInfo()
        this.getbrandList()
    },
    watch: {
        addVisible(oldVal, newVal) {
            this.getSearchGoodsInfo()
            this.getbrandList()
            this.addProductVisible = this.addVisible
        },
        goodsList(oldVal, newVal) {
            this.pGoodsList = this.goodsList
        },
    },
    methods: {

        handleSelectionChange(val) {
            this.selectProductList = val;
        },
        // 品牌列表
        getbrandList() {
            request.get('/goods/brand/list', this.queryParams).then(ret => {
                if (ret.code == 1) {
                    this.brandList = ret.data.list
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        getSearchGoodsInfo() {
            this.loading = true
            request.get('/goods/list', this.queryProductParams).then(ret => {
                if (ret.code == 1) {
                    this.pageInfo = ret.data
                    this.searchGoodsList = ret.data.list;
                    this.loading = false
                } else {
                    this.$message.error(ret.msg);
                }

            });
        },
        close() {
            this.queryProductParams = {
                page: 1,
                pagesize: 10,
                state: 2,
            }
            this.searchGoodsList = []
            this.$emit('toggleAdd', false);

        },
        // 商品选择
        submitProduct() {
            this.queryProductParams = {
                page: 1,
                pagesize: 10
            }
            this.searchGoodsList = []
            if (this.selectProductList.length <= 0) {
                this.$message.success('请选择商品');
                this.close()
                return
            } else {
                this.pGoodsList = this.pGoodsList.concat(this.selectProductList);
                let list = this.pGoodsList
                // console.log(555555, list)
                var newArr = []
                // 商品去重处理
                for (var i = 0; i < this.pGoodsList.length; i++) {
                    if (!newArr.includes(this.pGoodsList[i])) {
                        newArr.push(this.pGoodsList[i]);
                    }
                }
                this.pGoodsList = newArr;
                this.$emit('getAddGood', this.pGoodsList);
                this.selectProductList = []
                this.close()
            }

        },
        //分页
        changeQuery() {
            this.queryProductParams.page = 1;
            this.getSearchGoodsInfo();
        },
        refreshPageSize(val) {
            this.queryProductParams.pagesize = val;
            this.getSearchGoodsInfo();
        },
        refreshPageNum(val) {
            this.queryProductParams.page = val;
            this.getSearchGoodsInfo();
        },
    }
}
</script>